import PrismicPage from "~/views/PrismicPage";

function Page(props) {
  return <PrismicPage {...props} />;
}

Page.getInitialProps = async (ctx) => {
  try {
    ctx.query.pageUID = ctx.query.prismicUid;

    const viewProps = await PrismicPage.getInitialProps(ctx);

    // return the props for the Page component
    return {
      ...viewProps,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default Page;
